import React from 'react'
import Box from '../components/Box'
import Link from '../components/Link'

const BaronFooter = () => {
  return (
    <Box textAlign={'center'} pt="2em">
      <Link href="https://www.facebook.com/Info2Act">Info2Act</Link> x <Link href="https://relab.cc">RE:LAB</Link>
    </Box>
  )
}

export default BaronFooter
