import React, { forwardRef } from 'react';
import { Button as ChButton, IconButton } from "@chakra-ui/react";

import Link from './Link'
import { responsive } from './ThemeProvider/theme';

const Button = ({ href, to, ...props }) => {
  if ((href || to) && !props.isDisabled) return <ChButton as={p => <Link {...p} href={href} to={to} />} {...props} />
  return <ChButton {...props} />
}

Button.Black = props => (
  <Button
    bg="black"
    _hover={{ bg: 'black' }}
    {...props}
  />
)

Button.Secondary = props => (
  <Button colorScheme="green" {...props} />
)

Button.Danger = props => (
  <Button colorScheme="red" {...props} />
)

Button.Women = props => (
  <Button
    bg="rgba(158, 22, 22, 0.35)"
    color="white"
    height="auto"
    border="2px solid"
    borderRadius="full"
    letterSpacing="0.125rem"
    px={responsive('2.25rem', '1em')}
    py={responsive('0.875rem', '1em')}
    _hover={{ bg: 'rgba(158, 22, 22, 0.35)' }}
    textShadow="1px 3px 12px #952C2A"
    fontSize={responsive('1.125em', '1.5em')}
    fontWeight="500"
    {...props}
  />
)

Button.Icon = forwardRef(({ href, to, ...props }, ref) => {
  if (href || to) return <IconButton ref={ref} as={p => <Link {...p} href={href} to={to} ref={ref} />} {...props} />
  return <IconButton ref={ref} {...props} />
})

export default Button
