import React, { useState } from 'react'
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { concat, range, set, toString } from 'lodash'

import Box from '../components/Box'
import Flex from '../components/Flex'
import Text from '../components/Text'
import Button from '../components/Button'
import Container from '../components/Container'
import { responsive } from "../components/ThemeProvider/theme"
import BaronFooter from '../containers/BaronFooter'

const options = ['非常同意', '同意', '不同意', '非常不同意'];

const TestPage = ({ pageContext: { data, length, id }, location: { state } }) => {
  const [answers, handleAnswer] = useState([])
  const [scores, handleScore] = useState([])
  return (
    <Container maxWidth={responsive('auto', '60em')} px={responsive('1em', 0)} py="3em">
      <Flex px={responsive('1.5em', '3em')}>
        {range(length).map((d) => (
          <Box key={d} mx="0.25em" width="100%" height={'0.5em'} bg={id >= (d + 1) ? 'black': 'rgb(233, 236, 239)'} />
        ))}
      </Flex>
      {data?.map(({ q, score }, i) => (
        <Box my="4em" key={i}>
          <Box borderLeft={'4px solid'} borderColor="black" px={responsive('1em', '3em')}>
            <Text fontSize={responsive('1em', '1.25em')}>{(id - 1) * 10 + i + 1}. {q}</Text>
          </Box>
          <RadioGroup mt="1em" value={answers[i]} px={responsive('1em', '3em')}>
            <Stack spacing={4} direction={responsive('column', 'row')}>
              {options.map((opt, k) => (
                <Radio
                  onChange={(e) => {
                    set(answers, toString(i), e.target.value)
                    handleAnswer([...answers])
                    set(scores, toString(i), score[e.target.value])
                    handleScore([...scores])
                  }}
                  fontSize={responsive('0.875em', '1em')}
                  colorScheme='custom.black'
                  name={`answer.${i}`}
                  value={toString(k)}
                  key={k}
                  w={responsive('100%', '25%')}
                >{opt}</Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      ))}
      <Box textAlign={'center'}>
        <Button.Black
          isDisabled={answers.length < 10}
          state={{ answers: concat(state?.answers || [], answers), scores: concat(state?.scores || [], scores) }}
          to={length === id ?  `/q/baron-cohen/result` : `/q/baron-cohen/${id + 1}`}
        >{length === id ? '看結果' : '下一頁'}</Button.Black>
      </Box>
      <BaronFooter />
    </Container>
  )
}

export default TestPage
